import Github from "../data/github_icon.png";
import Fiverr from "../data/fiverr.svg";
import Instagram from "../data/instagram_icon.png";
import Email from "../data/email_icon.png";
import Fade from "react-reveal/Fade";
import SkillTag from "./Skilltag";
import { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import scripts from "../data/scripts.json";
import tags from "../data/tags.json";
import projectAdLogo from "../data/projectad.png";
import toast, { Toaster } from "react-hot-toast";
import KT from "../data/kreativtest_logo.png";
import EL from "../data/exent_logo.webp";
import IL from "../data/idea_logo.svg";
import HS from "../data/hypersku.png";
import IC from "../data/iurcrowd.svg";
import TI from "../data/trafficinspector.png";
import BWINF from "../data/bwinf.png";
import LinkedIn from "../data/linkedin.png";
// import Snowfall from 'react-snowfall';
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Snowfall from "react-snowfall";
import MirrorView from "../data/MirrorView.png";

function Main() {
  const [currentRandom, x] = useState(Math.floor(Math.random() * tags.length));
  const [currentRandom2, updateRan] = useState(Math.floor(Math.random() * 3));
  const [magic, setMagic] = useState(false);
  const [menu, setMenu] = useState(false);

  const [magicSelection, setMagicSelection] = useState(<div></div>);

  const [selection, setSelection] = useState("Android");
  const [projectS, setProjectS] = useState(-1);

  /// special game XX
  /*const [gameStatus, setGameStatus] = useState(false);
    const [gameTime, setGameTime] = useState("Reaction Time");
    const [currentLight, setCurrentLight] = useState(0);
    const [scoreboard, setScoreBoard] = useState([]);
    */
  console.log(selection);
  function link1() {
    if (magic) {
      return;
    }
    window.open(tags[currentRandom]["w"]);
  }
  function link2() {
    if (magic) {
      return;
    }
    window.open("https://www.instagram.com/valentinahrend1111/");
  }
  document.getElementById("root").style.overflow = "auto";
  function htmlView(x, y) {
    if (x.target === y) {
      console.log(x);
      const mg = document.getElementsByClassName("magic-rect")[0];
      mg.classList.add("megic");
      let xx = x.pageX + 200;
      if (xx > window.innerWidth) {
        xx = window.innerWidth - 20;
      }
      let y = x.pageY + 150;
      let z = window.getComputedStyle(
        document.getElementsByClassName("mxl")[0],
        null
      ).height;
      z = z.substring(0, z.length - 2);
      console.log(z);
      if (y > z) {
        y = z - 20;
        console.log(y);
      }
      /*if(y > window.innerHeight){
                y = window.innerHeight - 151;
            }*/

      const items0 = [];
      items0.push(
        <div className="tag-info">
          <div className="head-x" style={{ marginRight: "auto" }}>
            {"tagName"}
          </div>
          <div className="value-x" style={{ marginLeft: "auto" }}>
            {x.target.tagName}
          </div>
        </div>
      );
      for (const name of x.target.getAttributeNames()) {
        items0.push(
          <div className="tag-info">
            <div className="head-x" style={{ marginRight: "auto" }}>
              {name}
            </div>
            <div className="value-x" style={{ marginLeft: "auto" }}>
              {x.target.getAttribute(name)}
            </div>
          </div>
        );
      }
      const computedStyle = window.getComputedStyle(x.target, null);
      for (let prop in x.target.style) {
        if (Object.hasOwn(x.target.style, prop)) {
          items0.push(
            <div className="tag-info">
              <div className="head-x" style={{ marginRight: "auto" }}>
                {prop}
              </div>
              <div className="value-x" style={{ marginLeft: "auto" }}>
                {computedStyle[prop]}
              </div>
            </div>
          );
        }
      }

      mg.style.left = xx - 200 + "px";
      mg.style.top = y - 150 + "px";
      setMagicSelection(<div>{items0}</div>);
    }
  }
  useEffect(() => {
    document.getElementById("root").style.overflow = "auto";
    /// special game starts XX
    /*if(gameStatus && gameTime !== -2){
            if(currentLight < 5) {
                new Promise(x => setTimeout(x, 1000)).then(y => {
                    setCurrentLight(currentLight + 1);
                });
            }else{
                if(currentLight == 5){
                    console.log((Math.random() * 1000 + 1000).toFixed(0));
                    new Promise(x => setTimeout(x, (Math.random() * 2000 + 1000).toFixed(0))).then(ss => {
                        console.log("hello");
                        setCurrentLight(6);
                        console.log(6);
                        setGameTime(new Date().getTime());
                    });
                }
            }
        }else{
            if(gameTime == -2){
                new Promise(x => setTimeout(x, 2000)).then(y => {
                    setGameStatus(false);
                    setGameTime(-10);
                });
            }
        }*/
    /// special game ends XX

    new Promise((x) => setTimeout(x, 200)).then((y) => {
      document.getElementsByClassName(
        "row-seil-obj-inverse-2"
      )[0].style.height =
        Array.prototype.slice
          .call(document.getElementsByClassName("control-panel"))
          .map((x) => x.offsetHeight)
          .reduce((partialSum, a) => partialSum + a, 0) +
        document.getElementsByClassName("welcome-title")[0].offsetHeight +
        90 +
        "px";
    });
    if (magic) {
      new Promise((x) => setTimeout(x, 200)).then((y) => {
        for (const iterator of document.getElementsByTagName("div")) {
          iterator.onclick = (x) => {
            htmlView(x, iterator);
          };
        }
        for (const iterator of document.getElementsByTagName("a")) {
          iterator.onclick = (x) => {
            htmlView(x, iterator);
          };
        }
        for (const iterator of document.getElementsByTagName("img")) {
          iterator.onclick = (x) => {
            htmlView(x, iterator);
          };
        }
        for (const iterator of document.getElementsByTagName("p")) {
          iterator.onclick = (x) => {
            htmlView(x, iterator);
          };
        }
      });
    } else {
      new Promise((x) => setTimeout(x, 200)).then((y) => {
        const mg = document.getElementsByClassName("magic-rect")[0];
        mg.classList.remove("megic");
        for (const iterator of document.getElementsByTagName("div")) {
          iterator.onclick = (x) => {};
        }
        for (const iterator of document.getElementsByTagName("a")) {
          iterator.onclick = (x) => {};
        }
        for (const iterator of document.getElementsByTagName("img")) {
          iterator.onclick = (x) => {};
        }
        for (const iterator of document.getElementsByTagName("p")) {
          iterator.onclick = (x) => {};
        }
      });
    }
  });
  const middle = 125;

  /*
    const available = window.innerWidth < 1000 && gameTime != -10 && Cookies.get("gameDone") == undefined && document.referrer === "https://l.instagram.com/";
    const games = [];
    if(available) {
        games.push(<div id='game'>
            <div className='real-game' style={{"visibility": gameStatus && scoreboard.length == 0 ? "visible": "hidden", "height": gameStatus && scoreboard.length == 0 ? "max-content": "0px"}} onClick={()=>{
                if(gameTime.length != undefined && gameTime.endsWith("ms")) {
                    return;
                }
                if(gameTime == -1){
                    setGameTime(-2);
                    return;
                }
                if(currentLight === 6){
                    setCurrentLight(7);
                    const finishTime = new Date().getTime();
                    const difference = finishTime - gameTime;
                    setGameTime(difference.toString() + "ms");                   
                }else{
                    if(gameTime != -2 && currentLight != 7){
                        setGameTime(-1);
                    }
                }

            }}>
                <div className='light-time'>
                    <div className='game-start-inner' style={{"fontSize": "25px"}}>
                        {gameTime.length != undefined ? gameTime : (gameTime === -1 ? "Reaction Time": (gameTime == -2 ? "Game Over!": "Click!"))}
                    </div>
                </div>
                {
                    gameTime.length != undefined && gameTime.endsWith("ms") ? <div></div> : 
                <div className='lights-row'>
                    <div className={6 > currentLight && currentLight > 0 ? 'light red':(currentLight == 6 ? 'light green' : 'light')}></div>
                    <div className={6 > currentLight && currentLight > 1 ? 'light red':(currentLight == 6 ? 'light green' : 'light')}></div>
                    <div className={6 > currentLight && currentLight > 2 ? 'light red':(currentLight == 6 ? 'light green' : 'light')}></div>
                    <div className={6 > currentLight && currentLight > 3 ? 'light red':(currentLight == 6 ? 'light green' : 'light')}></div>
                    <div className={6 > currentLight && currentLight > 4 ? 'light red':(currentLight == 6 ? 'light green' : 'light')}></div>
                </div>
                }   
                <div className='light-time'>
                    <div className='game-start-inner'>
                        {gameTime === -1 ? "Click WHEN THE LIGHTS ARE GREEN!": (gameTime == -2 ? "Spamming isn't allowed!": gameTime.length != undefined && gameTime.endsWith("ms")?
                        <div>
                            <div className='game-start-inner' style={{"marginBottom": "15px"}}>
                                {"Enter your name and share your score in the leaderboard."}
                            </div>
                            <input name='name-enter' type="text" className='enter-element'></input>
                        </div>
                        :"Click at green and check out your reaction time!")}
                    </div>
                </div>
                {
                    gameTime.length != undefined && gameTime.endsWith("ms") ? <div className='game-exit' style={{"marginTop": "30px", "width": "150px"}} onClick={()=>{
                        if(document.getElementsByClassName('enter-element')[0].value){
                            const text = document.getElementsByClassName('enter-element')[0].value;
                            if(text.length > 1 && text.length < 100){
                                
                                fetch("https://idealabmatch.herokuapp.com/scoreboard?score=" + gameTime + "&name=" + text + "&lock=" + "924387", {method: "GET"})
                                .then((response) => response.json())
                                .then((data) => {
                                    /// returning the scoreboard
                                    data.sort((a, b) => parseInt(a.score.substring(0, a.score.length - 2), 10) - parseInt(b.score.substring(0, b.score.length - 2)), 10);
                                    setScoreBoard(data);
                                });

                                new Promise(x => setTimeout(x, 60000)).then(y => {
                                    Cookies.set("gameDone", "true");
                                });
                            }
                        }
                    }}>
                        <div className='game-start-inner'>Submit</div>
                    </div> : <div></div>
            </div>
            <div className='scoreboard' style={{"visibility": gameStatus && scoreboard.length != 0 ? "visible": "hidden", "height": gameStatus && scoreboard.length != 0 ? "max-content" : "0px"}}>
                <div className='exit1' onClick={()=>{
                    setGameStatus(false);
                    setGameTime(-10);
                    games.shift();
                    Cookies.set("gameDone", "true");
                }}>{"[exit]"}</div>
                <div className='welcome-title game-title' style={{"fontSize": "28px", "paddingBottom": "30px"}}>Leaderboard</div>
                <div className='score-list'>{scoreboard.map((score, index) => <div className='score-element' style={{"backgroundColor": index % 2 == 0 ? "none": "rgba(0,255,102,0.2)"}}>
                    <div className='score-subelement' style={{"width": "70px"}}>{"#"+(index+1).toString()}</div>
                    <div className='score-subelement' style={{"width": "200px"}}>{score.name}</div>
                    <div className='score-subelement' style={{"width": "100px"}}>{score.score}</div>
                </div>)}
                </div>
            </div>
            <div style={{"visibility": !gameStatus ? "visible": "hidden", "display": "flex", "flexDirection": "column", "height": !gameStatus ? "100%": "0px"}}>
            <div className='welcome-title game-title'>Wanna play a game?</div>
            <div className='game-start' onClick={()=>{
                setGameStatus(true);
                
            }}>
                <div className='game-start-inner'>Start Game</div>
            </div>
            <div className='game-exit' onClick={()=>{
                setGameStatus(false);
                setGameTime(-10);
                games.shift();
                Cookies.set("gameDone", "true");
            }}>
                <div className='game-start-inner'>Continue to website</div>
            </div>
            </div>
        </div>);
    }*/
  /*
    <Snowfall color='white' snowflakeCount={300}>
        </Snowfall>*/
  /*
    <div className='game-overlay' style={{"visibility": !available ? "hidden": "visible"}}>
            {games}
        </div>
        */

  const nav = useNavigate();

  return (
    <div className="mxl">
      <div
        className="mod-f"
        style={{
          overflowX: "hidden",
        }} /*style={{"maxHeight": games.length == 0 ? "x" : "100vh", "minHeight": games.length == 0 ? "5120px" : "100vh"}}*/
      >
        {/*<Snowfall color='white' snowflakeCount={800}></Snowfall>*/}
        <div className="magic-rect">
          <div className="m-text">{magicSelection}</div>
        </div>

        <div
          className="control-panel"
          style={{
            width:
              window.innerWidth < 600
                ? "calc(80% - 15% - 8.5px)"
                : "calc(43.56% - 45px)",
            backgroundColor: "red",
            height: "auto",
            top: "-200vw",
            position: "absolute",
            visibility: "hidden",
            maxWidth: "900px",
          }}
        >
          <div
            className="welcome-title"
            style={{
              fontSize: "32px",
              marginTop: "15px",
              marginLeft: "15px",
              textAlign: "left",
              letterSpacing: "2.5px",
              color: "transparent",
            }}
          >
            Favourite Tools
          </div>
          <div className="shields-container">
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div className="cat-c">
              <div className="cat-d">// Coding Skills</div>
            </div>
          </div>
          <div className="shields-container">
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div className="cat-c">
              <div className="cat-d">// Coding Environments & Cloud</div>
            </div>
          </div>
          <div className="shields-container">
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div className="cat-c">
              <div className="cat-d">// Favourite Editors</div>
            </div>
          </div>
          <div className="shields-container">
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div className="cat-c">
              <div className="cat-d">// Favourite Editors</div>
            </div>
          </div>
          <div className="shields-container">
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div
              height="25px"
              style={{
                paddingRight: "7.5px",
                paddingBottom: "7.5px",
                width: middle + "px",
                color: "transparent",
              }}
            >
              x
            </div>
            <div className="cat-c">
              <div className="cat-d">// On Daily Usage</div>
            </div>
          </div>
        </div>
        <div className="vct-log">
          <div className="vector-logo"></div>
        </div>
        <div id="header" style={{ height: menu ? "350px" : "150px" }}>
          <Fade top>
            <div
              className="real-header"
              style={{ height: menu ? "325px" : "50px" }}
            >
              <div className="real-header-s">
                <div className="title">
                  <div
                    className="title-inner"
                    style={{ cursor: "pointer", fontSize: "16px" }}
                  >
                    Valentin Ahrend
                  </div>
                </div>
                <div className="des-desktop">
                  <div className="des-inner">
                    <a
                      className="hgt"
                      href={!magic ? "#welcome" : "#"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setMenu(false);
                      }}
                    >
                      About
                    </a>
                    <a
                      className="hgt"
                      href={!magic ? "#skills" : "#"}
                      style={{ paddingLeft: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setMenu(false);
                      }}
                    >
                      Skills
                    </a>
                    <a
                      className="hgt"
                      href={!magic ? "#projects" : "#"}
                      style={{ paddingLeft: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setMenu(false);
                      }}
                    >
                      Projects
                    </a>
                    <a
                      className="hgt"
                      href={!magic ? "#contact" : "#"}
                      style={{ paddingLeft: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setMenu(false);
                      }}
                    >
                      Contact
                    </a>
                  </div>
                </div>
                <div className="des-mobile">
                  <div
                    className="selectp"
                    style={{
                      transform: menu ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                    onClick={() => {
                      setMenu(!menu);
                    }}
                  ></div>
                </div>
              </div>
              <div
                className="menus"
                style={{
                  visibility: menu ? "visible" : "hidden",
                  pointerEvents: menu ? "all" : "none",
                }}
              >
                <div style={{ display: "flex" }}>
                  <a
                    className="hgt hgg"
                    href={!magic ? "#welcome" : "#"}
                    onClick={() => {
                      setMenu(false);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="xi">About</div>
                  </a>
                </div>
                <div style={{ display: "flex" }}>
                  <a
                    className="hgt hgg"
                    href={!magic ? "#skills" : "#"}
                    onClick={() => {
                      setMenu(false);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="xi">Skills</div>
                  </a>
                </div>
                <div style={{ display: "flex" }}>
                  <a
                    className="hgt hgg"
                    href={!magic ? "#projects" : "#"}
                    onClick={() => {
                      setMenu(false);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="xi">Projects</div>
                  </a>
                </div>
                <div style={{ display: "flex" }}>
                  <a
                    className="hgt hgg"
                    href={!magic ? "#contact" : "#"}
                    onClick={() => {
                      setMenu(false);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="xi">Contact</div>
                  </a>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <div id="welcome">
          <Fade delay={200}>
            <div className="welcome-inner">
              <div className="welcome-title">Hi, I'm Valentin Ahrend</div>
              <div className="welcome-des">
                I'm a software developer and student, mainly interested in Apps,
                Web, Serverside, AI and Physics
              </div>
              <div className="welcome-options">
                <div
                  className="welcome-option-inner"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    window.open("https://github.com/ValentinAhrend");
                  }}
                >
                  <img src={Github} className="welcome-option-inner-img"></img>
                </div>
                <div
                  className="welcome-option-inner"
                  style={{
                    marginRight: "20px",
                    height: "40px",
                    display: "flex",
                  }}
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    window.open("https://fiverr.com/valentinahrend");
                  }}
                >
                  <img
                    src={Fiverr}
                    className="welcome-option-inner-img"
                    style={{
                      height: "40px",
                      marginTop: "auto",
                      marginBottom: "auto",
                      display: "inline-block",
                      transform: "scale(1.1)",
                    }}
                  ></img>
                </div>

                <div
                  className="welcome-option-inner"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    window.open("https://instagram.com/vali.b05");
                  }}
                >
                  <img
                    src={Instagram}
                    className="welcome-option-inner-img"
                  ></img>
                </div>
                <div
                  className="welcome-option-inner"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    window.open(
                      "https://www.linkedin.com/in/valentin-ahrend-583983311/"
                    );
                  }}
                >
                  <img
                    src={LinkedIn}
                    className="welcome-option-inner-img"
                  ></img>
                </div>
                <div className="welcome-option-inner"></div>
              </div>
            </div>
          </Fade>
        </div>
        <div className="row-seil">
          <div className="row-seil-tag">Freetime</div>
          <div className="row-seil-container"></div>
          <div className="row-seil-button">
            <div className="row-seil-pickle"></div>
            <Fade delay={400}>
              <div className="row-seil-obj">
                <div className="welcome-title" style={{ fontSize: "32px" }}>
                  In my freetime
                </div>
                <div
                  className="welcome-des"
                  style={{ fontSize: "calc(1.3 * 16px)", marginTop: "10px" }}
                >
                  I play tennis, golf, the piano and I'm creative
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <Fade>
          <div
            className="skills"
            id="skills"
            style={{
              height:
                window.innerWidth < 1500
                  ? window.innerWidth < 1000
                    ? "fit-content"
                    : "800px"
                  : "unset",
            }}
          >
            <div className="welcome-title">Skills</div>
            <div
              className="welcome-des"
              style={{ marginTop: "30px", maxWidth: "750px" }}
            >
              I taught myself Java at 11 years and then continued with various
              other markup, script and object-oriented languages.
            </div>
            <div>
              {window.innerWidth < 1500 ? (
                <div className="skills-container">
                  <div className="skill-selector">
                    <SkillTag
                      language={"Android"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Dart"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Figma"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Flutter"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"HTML/CSS"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"iOS"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Java"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"JavaScript"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"NLP"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Python"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"React"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Selenium"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"SpringBoot"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Swift"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                  </div>
                  <div className="skill-preview">
                    <div className="rect0">
                      {selection == "Figma" || selection == "NLP" ? (
                        selection == "Figma" ? (
                          <div className="figma-x"></div>
                        ) : (
                          <div className="nlp-x">
                            <div className="nlp-xy"></div>
                          </div>
                        )
                      ) : (
                        <div
                          style={{
                            paddingTop: "25px",
                            paddingLeft: "25px",
                            position: "relative",
                            height: "350px",
                            minWidth: "800px",
                          }}
                        >
                          <SyntaxHighlighter
                            language={scripts[selection.toLowerCase()]["lang"]}
                            style={atomOneDark}
                            customStyle={{
                              overflowY: "hidden",
                              overflowX: "scroll",
                              backgroundColor: "transparent",
                              margin: "0",
                              height: "320px",
                              width: "500px",
                            }}
                          >
                            {scripts[selection.toLowerCase()]["code"]}
                          </SyntaxHighlighter>
                          <div className="origin-info">
                            <div
                              className="def-main"
                              style={{
                                cursor:
                                  selection == "JavaScript"
                                    ? "pointer"
                                    : "auto",
                              }}
                              onClick={() => {
                                if (selection == "JavaScript") {
                                  nav("/hello");
                                }
                              }}
                            >
                              {scripts[selection.toLowerCase()]["from"]}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="rect1">
                      <div className="skill-prev-header">{selection}</div>
                      {selection == "HTML/CSS" ? (
                        <div className="skill-prev-des" onClick={link1}>
                          <div>
                            {scripts[selection.toLowerCase()]["des"]}
                            <b>{tags[currentRandom]["name"]}</b>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: tags[currentRandom]["usage"],
                            }}
                          ></div>
                        </div>
                      ) : selection == "Selenium" ? (
                        <div className="skill-prev-des" onClick={link2}>
                          {scripts[selection.toLowerCase()]["des"]}
                        </div>
                      ) : (
                        <div className="skill-prev-des">
                          {scripts[selection.toLowerCase()]["des"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="skill-preview-mobile">
                    <div className="rect2">
                      {selection == "Figma" || selection == "NLP" ? (
                        selection == "Figma" ? (
                          <div
                            className="figma-x"
                            style={{ width: "550px" }}
                          ></div>
                        ) : (
                          <div className="nlp-x">
                            <div className="nlp-xy"></div>
                          </div>
                        )
                      ) : (
                        <div
                          style={{
                            paddingTop: "15px",
                            paddingLeft: "15px",
                            position: "relative",
                            height: "calc(100% - 15px)",
                            overflowX: "scroll",
                            width: "calc(100% - 15px)",
                          }}
                        >
                          <SyntaxHighlighter
                            language={scripts[selection.toLowerCase()]["lang"]}
                            style={atomOneDark}
                            customStyle={{
                              overflowY: "hidden",
                              overflowX: "scroll",
                              backgroundColor: "transparent",
                              margin: "0",
                              width: "fit-content",
                              height: "calc(95% - 30px)",
                            }}
                          >
                            {scripts[selection.toLowerCase()]["code"]}
                          </SyntaxHighlighter>
                          <div className="origin-info">
                            <div
                              className="def-main"
                              style={{
                                cursor:
                                  selection == "JavaScript"
                                    ? "pointer"
                                    : "auto",
                              }}
                              onClick={() => {
                                if (selection == "JavaScript") {
                                  nav("/hello");
                                }
                              }}
                            >
                              {scripts[selection.toLowerCase()]["from"]}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="rect3">
                      <div className="skill-prev-header">{selection}</div>
                      {selection == "HTML/CSS" ? (
                        <div className="sklp">
                          <div className="skill-prev-des" onClick={link1}>
                            <div>
                              {scripts[selection.toLowerCase()]["des"]}
                              <b>{tags[currentRandom]["name"]}</b>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: tags[currentRandom]["usage"],
                              }}
                            ></div>
                          </div>
                        </div>
                      ) : selection == "Selenium" ? (
                        <div className="sklp">
                          <div className="skill-prev-des" onClick={link2}>
                            {scripts[selection.toLowerCase()]["des"]}
                          </div>
                        </div>
                      ) : (
                        <div className="sklp">
                          <div className="skill-prev-des">
                            {scripts[selection.toLowerCase()]["des"]}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="skills-container">
                  <div className="skill-preview">
                    <div className="rect0">
                      {selection == "Figma" || selection == "NLP" ? (
                        selection == "Figma" ? (
                          <div className="figma-x"></div>
                        ) : (
                          <div className="nlp-x">
                            <div className="nlp-xy"></div>
                          </div>
                        )
                      ) : (
                        <div
                          style={{
                            paddingTop: "25px",
                            paddingLeft: "25px",
                            position: "relative",
                            height: "350px",
                            minWidth: "800px",
                          }}
                        >
                          <SyntaxHighlighter
                            language={scripts[selection.toLowerCase()]["lang"]}
                            style={atomOneDark}
                            customStyle={{
                              overflowY: "hidden",
                              overflowX: "scroll",
                              backgroundColor: "transparent",
                              margin: "0",
                              height: "320px",
                              width: "500px",
                            }}
                          >
                            {scripts[selection.toLowerCase()]["code"]}
                          </SyntaxHighlighter>
                          <div className="origin-info">
                            <div
                              className="def-main"
                              style={{
                                cursor:
                                  selection == "JavaScript"
                                    ? "pointer"
                                    : "auto",
                              }}
                              onClick={() => {
                                if (selection == "JavaScript") {
                                  nav("/hello");
                                }
                              }}
                            >
                              {scripts[selection.toLowerCase()]["from"]}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="rect1">
                      <div className="skill-prev-header">{selection}</div>
                      {selection == "HTML/CSS" ? (
                        <div className="skill-prev-des" onClick={link1}>
                          <div>
                            {scripts[selection.toLowerCase()]["des"]}
                            <b>{tags[currentRandom]["name"]}</b>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: tags[currentRandom]["usage"],
                            }}
                          ></div>
                        </div>
                      ) : selection == "Selenium" ? (
                        <div className="skill-prev-des" onClick={link2}>
                          {scripts[selection.toLowerCase()]["des"]}
                        </div>
                      ) : (
                        <div className="skill-prev-des">
                          {scripts[selection.toLowerCase()]["des"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="skill-selector"
                    style={{ paddingLeft: "60px" }}
                  >
                    <SkillTag
                      language={"Android"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Dart"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Figma"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Flutter"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"HTML/CSS"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"iOS"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Java"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"JavaScript"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"NLP"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Python"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"React"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Selenium"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"SpringBoot"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                    <SkillTag
                      language={"Swift"}
                      onSelect={setSelection}
                      selectx={selection}
                    ></SkillTag>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fade>
        <div
          className="row-seil"
          style={{ height: "1400px", position: "relative" }}
        >
          <div className="row-seil-tag-x inverse">Style</div>
          <div
            className="row-seil-tag-x inverse"
            style={{
              top: "calc(663px - 4px)",
              width: "180px",
              right: "calc(33% - 200px)",
            }}
          >
            Favourite Tools
          </div>
          <div className="row-seil-container-reverse"></div>
          <div className="row-seil-button-inverse">
            <div className="row-seil-pickle-inverse"></div>
            <Fade delay={400}>
              <div className="row-seil-obj-inverse">
                <div
                  className="welcome-des"
                  style={{
                    fontSize: "calc(1.3 * 16px)",
                    marginTop: "0px",
                    textAlign: "right",
                    marginLeft: "auto",
                  }}
                >
                  I like creating apps and websites in a clean and{" "}
                  <u>minimalistic</u> style.
                </div>
              </div>
            </Fade>
          </div>
          <div className="sqrt">
            <div className="row-seil-pickle-inverse"></div>
          </div>
          <div className="row-seil-button-inverse-2">
            <Fade delay={400}>
              <div className="row-seil-obj-inverse-2">
                <div
                  className="welcome-title"
                  style={{
                    fontSize: "32px",
                    marginTop: "15px",
                    marginLeft: "15px",
                    textAlign: "left",
                    letterSpacing: "2.5px",
                  }}
                >
                  Favourite Tools
                </div>
                <div className="shields-container">
                  <img
                    src="https://img.shields.io/badge/Code-React-informational?style=flat&logo=react&color=61DAFB"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Code-Java-informational?style=flat&logo=IntelliJ%20Idea&color=FFF"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Code-JavaScript-informational?style=flat&logo=JavaScript&color=F7DF1E"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Code-Python-informational?style=flat&logo=Python&color=3673a7"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Code-Swift-informational?style=flat&logo=Swift&color=CC342D"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Code-HTML5-informational?style=flat&logo=HTML5&color=E34F26"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Code-Dart-informational?style=flat&logo=Dart&color=003B57"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Code-Flutter-informational?style=flat&logo=Flutter&color=003B57"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Code-SpringBoot-informational?style=flat&logo=SpringBoot&color=6db33f"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <div className="cat-c">
                    <div className="cat-d">// Coding Skills</div>
                  </div>
                </div>
                <div className="shields-container">
                  <img
                    src="https://img.shields.io/badge/Cloud-Heroku-informational?style=flat&logo=Heroku&color=FFF"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Environment-Android-informational?style=flat&logo=Android&color=3ddc84"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Environment-IOS-informational?style=flat&logo=IOS&color=FFFFFF"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Cloud-Firebase-informational?style=flat&logo=Firebase&color=FFA611"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Cloud-Google-informational?style=flat&logo=Google&color=5187ec"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Environment-Express.js-informational?style=flat&logo=Express&color=white"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <div className="cat-c">
                    <div className="cat-d">// Coding Environments & Cloud</div>
                  </div>
                </div>
                <div className="shields-container">
                  <img
                    src="https://img.shields.io/badge/API-Selenium-informational?style=flat&logo=Selenium&color=43b02a"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/API-Puppeteer-informational?style=flat&logo=Puppeteer&color=00D8A2"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/API-SwiftyInsta-informational?style=flat&logo=Swift&color=F05138"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/API-Spacy-informational?style=flat&color=FF82FF"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/API-Numpy-informational?style=flat&logo=Numpy&color=306998"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <div className="cat-c">
                    <div className="cat-d">// Favourite APIs</div>
                  </div>
                </div>
                <div className="shields-container">
                  <img
                    src="https://img.shields.io/badge/Editor-VisualStudioCode-informational?style=flat&logo=VisualStudioCode&color=3295FE"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Editor-PyCharm-informational?style=flat&logo=PyCharm&color=fcf76b"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Editor-AndroidStudio-informational?style=flat&logo=AndroidStudio&color=3ddc84"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Editor-AndroidStudio-informational?style=flat&logo=AndroidStudio&color=3ddc84"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Cloud-Github-informational?style=flat&logo=Github&color=FFF"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <div className="cat-c">
                    <div className="cat-d">// Favourite Editors</div>
                  </div>
                </div>
                <div className="shields-container">
                  <img
                    src="https://img.shields.io/badge/Search-Stackoverflow-informational?style=flat&logo=Stackoverflow&color=F47F24"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Design-Figma-informational?style=flat&logo=Figma&color=ee6e38"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <img
                    src="https://img.shields.io/badge/Music-Spotify-informational?style=flat&logo=Spotify&color=1db954"
                    loading="lazy"
                    height="25px"
                    style={{ paddingRight: "7.5px", paddingBottom: "7.5px" }}
                  ></img>
                  <div className="cat-c">
                    <div className="cat-d">// On Daily Usage</div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <Fade when={true}>
          <div
            className="skills"
            id="projects"
            style={{
              height: window.innerWidth < 1000 ? "800px" : "x",
              width: window.innerWidth < 1000 ? "83%" : "x",
            }}
          >
            <div className="welcome-title">Projects</div>
            <div
              className="welcome-des"
              style={{ marginTop: "30px", maxWidth: "800px" }}
            >
              Instead of code tutorials I learned through personal projects.
              I've built apps, websites, bots, server applications, trained
              models and algorithms.
            </div>
            <div className="project-fl">
              <div className="project-container project-desk">
                <div className="project-gradient"></div>
                <div className="project-hold"></div>
                <div
                  className="project-file"
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    if (projectS == 14 || projectS == 214) {
                      setProjectS(-1);
                    } else {
                      setProjectS(14);
                    }
                  }}
                  style={{
                    height:
                      projectS == 14 || projectS == 214 ? "900px" : "400px",
                  }}
                >
                  <div
                    className="project-img"
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={TI}
                      style={{
                        width: window.innerWidth < 1000 ? "280px" : "300px",
                      }}
                      alt={"TrafficInspector"}
                    ></img>
                  </div>
                  <div className="project-back-blur"></div>
                  {projectS == 14 || projectS == 214 ? (
                    <div className={"project-long"}>
                      <div className="project-text0">
                        <b>TrafficInspector</b> is an internal tool for
                        analyzing traffic videos. The tool uses algorithms and
                        neural networks to track and classify objects on
                        low-resolution videos.{" "}
                      </div>
                      <Fade when={projectS == 14}>
                        <div
                          className="project-text0"
                          style={{ maxHeight: "550px", height: "fit-content" }}
                        >
                          {
                            "The project is build with Python and JavaScript and includes a web interface as well as a serverside. Currently this project is used internally and isn't open source."
                          }
                        </div>
                      </Fade>
                      <Fade delay={500} when={projectS == 14}>
                        <div className="but-p">
                          <a
                            onClick={() => {
                              setProjectS(214);
                              new Promise((x) => setTimeout(x, 500)).then(
                                (y) => {
                                  setProjectS(-1);
                                }
                              );
                            }}
                            className="btz btz-t"
                            style={{
                              border: "1px solid rgba(77, 25, 168, 0.8)",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              Close Details
                            </div>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <div className="project-text">
                      <div className="project-text0">
                        <b>TrafficInspector</b> is an internal tool for
                        analyzing traffic videos. The tool uses algorithms and
                        neural networks to track and classify objects on
                        low-resolution videos.{" "}
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "50pxs" }}></div>
                <div
                  className="project-file"
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    if (projectS == 13 || projectS == 213) {
                      setProjectS(-1);
                    } else {
                      setProjectS(13);
                    }
                  }}
                  style={{
                    height:
                      projectS == 13 || projectS == 213 ? "900px" : "400px",
                  }}
                >
                  <div
                    className="project-img"
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={"https://verbalcreativity.de/vector-logo.svg"}
                      style={{
                        width: window.innerWidth < 1000 ? "280px" : "300px",
                      }}
                      alt={"VerbalCreativity.de"}
                    ></img>
                  </div>
                  <div className="project-back-blur"></div>
                  {projectS == 13 || projectS == 213 ? (
                    <div className={"project-long"}>
                      <div className="project-text0">
                        <b>VerbalCreativity.de</b> is a website containing a 10
                        word verbal creativity test and research project
                        established with ReactJS and AWS Lambdas.
                      </div>
                      <Fade when={projectS == 13}>
                        <div
                          className="project-text0"
                          style={{ maxHeight: "250px", height: "fit-content" }}
                        >
                          <span>
                            {
                              'It\'s a final open research website to the "KreativTest" project. Test results are stored in a database and prepared for analysis. Underlined by the scientific discoveries and study below, the german version of this thinking test shall emphasis the importance of creativity and is an interesting self test.'
                            }
                          </span>
                        </div>
                      </Fade>
                      <Fade delay={500} when={projectS == 13}>
                        <div className="but-p">
                          <a
                            href={
                              !magic ? "https://verbalcreativity.de/test" : "#"
                            }
                            className="btz"
                            style={{
                              backgroundColor: "#C2C2C2",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 Take the test
                            </div>
                          </a>
                          <a
                            href={
                              !magic
                                ? "https://www.pnas.org/doi/10.1073/pnas.2022340118/"
                                : "#"
                            }
                            className="btz"
                            style={{
                              marginTop: "15px",
                              backgroundColor: "#C2C2C2",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🧪 Scientific Paper
                            </div>
                          </a>
                          <a
                            href={!magic ? "https://verbalcreativity.de/" : "#"}
                            className="btz"
                            style={{
                              marginTop: "15px",
                              backgroundColor: "#C2C2C2",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 verbalcreativity.de
                            </div>
                          </a>
                          <a
                            onClick={() => {
                              setProjectS(213);
                              new Promise((x) => setTimeout(x, 500)).then(
                                (y) => {
                                  setProjectS(-1);
                                }
                              );
                            }}
                            className="btz btz-nn"
                            style={{
                              border: "1px solid #C2C2C2",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              Close Details
                            </div>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <div className="project-text">
                      <div className="project-text0">
                        <b>VerbalCreativity.de</b> is a website containing a 10
                        word verbal creativity test and research project
                        established with ReactJS and AWS Lambdas.
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "50px" }}></div>
                <div
                  className="project-file"
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    if (projectS == 12 || projectS == 212) {
                      setProjectS(-1);
                    } else {
                      setProjectS(12);
                    }
                  }}
                  style={{
                    height:
                      projectS == 12 || projectS == 212 ? "900px" : "400px",
                  }}
                >
                  <div
                    className="project-img"
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={IC}
                      style={{
                        width: window.innerWidth < 1000 ? "280px" : "300px",
                      }}
                      alt={"iur.crowd"}
                    ></img>
                  </div>
                  <div className="project-back-blur"></div>
                  {projectS == 12 || projectS == 212 ? (
                    <div className={"project-long"}>
                      <div className="project-text0">
                        <b>iur.crowd</b> is a Berlin-based legal tech platform
                        offering research and analytics tools. I'm responsible
                        for the React-based frontend using ChakraUI and Amplify.
                      </div>
                      <Fade when={projectS == 12}>
                        <div
                          className="project-text0"
                          style={{ maxHeight: "550px", height: "fit-content" }}
                        >
                          {
                            "As part of my responsibilities, I efficiently implement new features and designs, swiftly address bugs, and adapt to evolving requirements. Working collaboratively with a team, I have gained valuable experience in new workflows and established meaningful connections with colleagues."
                          }
                        </div>
                      </Fade>
                      <Fade delay={500} when={projectS == 12}>
                        <div className="but-p">
                          <a
                            href={!magic ? "https://iurcrowd.de/" : "#"}
                            className="btz"
                            style={{
                              backgroundColor: "rgba(220, 186, 114, 0.8)",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 Open iurcrowd.de
                            </div>
                          </a>
                          <a
                            href={!magic ? "https://www.app.iurcrowd.de/" : "#"}
                            className="btz"
                            style={{
                              marginTop: "15px",
                              backgroundColor: "rgba(220, 186, 114, 0.8)",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 Open iur.crowd platform
                            </div>
                          </a>
                          <a
                            onClick={() => {
                              setProjectS(212);
                              new Promise((x) => setTimeout(x, 500)).then(
                                (y) => {
                                  setProjectS(-1);
                                }
                              );
                            }}
                            className="btz btz-i"
                            style={{
                              border: "1px solid rgba(220, 186, 114, 0.8)",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              Close Details
                            </div>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <div className="project-text">
                      <div className="project-text0">
                        <b>iur.crowd</b> is a Berlin-based legal tech platform
                        offering research and analytics tools. I'm responsible
                        for the React-based frontend using ChakraUI and Amplify.
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "50px" }}></div>
                <div
                  className="project-file"
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    if (projectS == 3 || projectS == 203) {
                      setProjectS(-1);
                    } else {
                      setProjectS(3);
                    }
                  }}
                  style={{
                    height:
                      projectS == 3 || projectS == 203 ? "900px" : "400px",
                  }}
                >
                  <div className="project-img">
                    <div className="app-log">
                      <img
                        className="app-logo0"
                        src={IL}
                        style={{
                          width: window.innerWidth < 1000 ? "300px" : "320px",
                          backgroundColor: "unset",
                        }}
                        alt={"Exsent"}
                      ></img>
                    </div>
                  </div>
                  <div className="project-back-blur"></div>
                  {projectS == 3 || projectS == 203 ? (
                    <div className={"project-long"}>
                      <div className="project-text0">
                        <b>IdeaMatch!</b> is a matching portal (web app) from
                        IdeaLab! that matches ideas and co-founders. I developed
                        it with React (frontend) and Express (backend).
                      </div>
                      <Fade when={projectS == 3}>
                        <div
                          className="project-text0"
                          style={{ maxHeight: "500px", height: "auto" }}
                        >
                          {
                            "The website and server contain a beautiful web design and secure auth service. I built this project for IdeaLab! – WHU Founders Conference"
                          }
                        </div>
                      </Fade>
                      <Fade delay={500} when={projectS == 3}>
                        <div className="but-p">
                          <a
                            href={!magic ? "https://idealabmatch.io" : "#"}
                            className="btz"
                            style={{
                              backgroundColor: "rgb(0,255,102)",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 Visit idealabmatch.io
                            </div>
                          </a>
                          <a
                            href={!magic ? "https://idealab.io" : "#"}
                            className="btz"
                            style={{
                              marginTop: "15px",
                              backgroundColor: "rgb(0,255,102)",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 Visit idealab.io
                            </div>
                          </a>
                          <a
                            onClick={() => {
                              setProjectS(203);
                              new Promise((x) => setTimeout(x, 500)).then(
                                (y) => {
                                  setProjectS(-1);
                                }
                              );
                            }}
                            className="btz btz-y "
                            style={{
                              border: "1px solid rgb(0,255,102)",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              Close Details
                            </div>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <div className="project-text">
                      <div className="project-text0">
                        <b>IdeaMatch!</b> is a matching portal (web app) from
                        IdeaLab! that matches ideas and co-founders. I developed
                        it with React (frontend) and Express (backend).
                      </div>
                    </div>
                  )}
                </div>
                
                <div style={{ width: "50px" }}></div>
                <div
                  className="project-file"
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    if (projectS == 56 || projectS == 256) {
                      setProjectS(-1);
                    } else {
                      setProjectS(56);
                    }
                  }}
                  style={{
                    height:
                      projectS == 56 || projectS == 256 ? "900px" : "400px",
                  }}
                >
                  <div className="project-img">
                    <div className="app-log">
                      <img
                        className="app-logo0"
                        src={MirrorView}
                        style={{
                          width: window.innerWidth < 1000 ? "300px" : "320px",
                          backgroundColor: "unset",
                        }}
                        alt={"MirrorView AI"}
                      ></img>
                    </div>
                  </div>
                  <div className="project-back-blur"></div>
                  {projectS == 56 || projectS == 256 ? (
                    <div className={"project-long"}>
                      <div className="project-text0">
                      <b>MirrorView AI</b> is a mobile application that enhances your interview and speaking skills with real-time feedback, personalized CV testing and real-life job information.
                      </div>
                      <Fade when={projectS == 56}>
                        <div
                          className="project-text0"
                          style={{ maxHeight: "500px", height: "auto" }}
                        >
                          {
                            "I developed the total application containing Firebase Functions, react webiste and flutter app in 72 hours in summer 2024."
                          }
                        </div>
                      </Fade>
                      <Fade delay={500} when={projectS == 56}>
                        <div className="but-p">
                          <a
                            href={!magic ? "https://mirrorview-ai.web.app/contact" : "#"}
                            className="btz"
                            style={{
                              backgroundColor: "rgb(202, 186, 244)",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 Visit Project Website
                            </div>
                          </a>
                          <a
                            href={!magic ? "https://ai.google.dev/competition/projects/mirrorview-ai" : "#"}
                            className="btz"
                            style={{
                              backgroundColor: "rgb(202, 186, 244)",
                              color: "black",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                             🧑‍💻 Gemini AI Competition 
                            </div>
                          </a>
                          <a
                            href={!magic ? "https://youtu.be/FVPuF_wEmBk?si=oVX34hBrEQNkcT0o" : "#"}
                            className="btz"
                            style={{
                              backgroundColor: "rgb(202, 186, 244)",
                              color: "black",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                             📀 Demo Video
                            </div>
                          </a>
                          <a
                            onClick={() => {
                              setProjectS(256);
                              new Promise((x) => setTimeout(x, 500)).then(
                                (y) => {
                                  setProjectS(-1);
                                }
                              );
                            }}
                            className="btz btz-y "
                            style={{
                              border: "1px solid rgb(227, 227, 227)",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              Close Details
                            </div>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <div className="project-text">
                      <div className="project-text0">
                      <b>MirrorView AI</b> is a mobile application that enhances your interview and speaking skills with real-time feedback, personalized CV testing and real-life job information
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "50px" }}></div>
                <div
                  className="project-file"
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    if (projectS == 0 || projectS == 200) {
                      setProjectS(-1);
                    } else {
                      setProjectS(0);
                    }
                  }}
                  style={{
                    height:
                      projectS == 0 || projectS == 200 ? "900px" : "400px",
                  }}
                >
                  <div className="project-img">
                    <img
                      src={KT}
                      style={{
                        width: window.innerWidth < 1000 ? "300px" : "320px",
                      }}
                      alt={"KreativTest"}
                    ></img>
                  </div>
                  <div className="project-back-blur"></div>
                  {projectS == 0 || projectS == 200 ? (
                    <div className={"project-long"}>
                      <div className="project-text0">
                        <b>KreativTest</b> is the first digital creativity test.
                        I developed it using (Python) and Flutter. This project
                        will soon be published on iOS, Android and Desktop.
                      </div>
                      <Fade when={projectS == 0}>
                        <div
                          className="project-text0"
                          style={{ maxHeight: "500px" }}
                        >
                          {
                            "I translated and optimized the python code into dart, which increased the speed of the validations a lot. The test contains 6 special tasks for validating 5 different aspects of creaitivty: Vocab, Combination, Problem Solving, Poetry, Association."
                          }
                        </div>
                      </Fade>
                      <Fade delay={500} when={projectS == 0}>
                        <div className="but-p">
                          <a
                            href={
                              !magic
                                ? "https://pitch-hisser-5d9.notion.site/Validierung-von-Kreativit-t-durch-KI-424f86d400724c27aeab6bce77ac47ca"
                                : "#"
                            }
                            className="btz"
                            style={{
                              backgroundColor: "rgb(105,220,178)",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 See on Notion
                            </div>
                          </a>
                          <a
                            onClick={() => {
                              setProjectS(200);
                              new Promise((x) => setTimeout(x, 500)).then(
                                (y) => {
                                  setProjectS(-1);
                                }
                              );
                            }}
                            className="btz btz-l"
                            style={{
                              border: "1px solid rgb(105,220,178)",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              Close Details
                            </div>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <div className="project-text">
                      <div className="project-text0">
                        <b>KreativTest</b> is the first digital creativity test.
                        I developed it using (Python) and Flutter. This project
                        will soon be published on iOS, Android and Desktop.
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "50px" }}></div>
                <div
                  className="project-file"
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    if (projectS == 9 || projectS == 209) {
                      setProjectS(-1);
                    } else {
                      setProjectS(9);
                    }
                  }}
                  style={{
                    height:
                      projectS == 9 || projectS == 209 ? "900px" : "400px",
                  }}
                >
                  <div className="project-img">
                    <div className="app-log">
                      <img
                        className="app-logo0"
                        src={HS}
                        style={{
                          width: window.innerWidth < 1000 ? "300px" : "320px",
                        }}
                        alt={"KreativTest"}
                      ></img>
                    </div>
                  </div>
                  <div className="project-back-blur"></div>
                  {projectS == 9 || projectS == 209 ? (
                    <div className={"project-long"}>
                      <div className="project-text0">
                        <b>HyperSKU</b> is a utility tool (iOS-App) for scanning
                        shoe labels. Built with Swift and Python. Using the
                        Swift VisionKit
                      </div>
                      <Fade when={projectS == 9}>
                        <div
                          className="project-text0"
                          style={{ maxHeight: "500px" }}
                        >
                          {
                            "This app is written in Swift and includes a python validation server. For getting a license key send a request to valentinahrend@gmail.com or on Discord to Vali#2865."
                          }
                        </div>
                      </Fade>
                      <Fade delay={500} when={projectS == 9}>
                        <div className="but-p">
                          <a
                            href={
                              !magic
                                ? "https://apps.apple.com/us/app/hypersku/id1641187929"
                                : "#"
                            }
                            className="btz"
                            style={{
                              backgroundColor: "rgb(139, 30, 23)",
                              color: "white",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              {"🔗 See on AppStore"}
                            </div>
                          </a>
                          <a
                            onClick={() => {
                              setProjectS(209);
                              new Promise((x) => setTimeout(x, 500)).then(
                                (y) => {
                                  setProjectS(-1);
                                }
                              );
                            }}
                            className="btz btz-f"
                            style={{
                              border: "1px solid rgb(139, 30, 23)",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              Close Details
                            </div>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <div className="project-text">
                      <div className="project-text0">
                        <b>HyperSKU</b> is a utility tool (iOS-App) for scanning
                        shoe labels. Built with Swift and Python. Using the
                        Swift VisionKit
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "50px" }}></div>
                <div
                  className="project-file"
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    if (projectS == 55 || projectS == 255) {
                      setProjectS(-1);
                    } else {
                      setProjectS(55);
                    }
                  }}
                  style={{
                    height:
                      projectS == 55 || projectS == 255 ? "900px" : "400px",
                  }}
                >
                  <div className="project-img">
                    <div className="app-log">
                      <img
                        className="app-logo0"
                        src={BWINF}
                        style={{
                          width: window.innerWidth < 1000 ? "300px" : "320px",
                          backgroundColor: "unset",
                        }}
                        alt={"Exsent"}
                      ></img>
                    </div>
                  </div>
                  <div className="project-back-blur"></div>
                  {projectS == 55 || projectS == 255 ? (
                    <div className={"project-long"}>
                      <div className="project-text0">
                        <b>BWINF!</b> is the national computer science competition of Germany. I took part in this competition. You can see the code, the docs and the program as a zip below.
                      </div>
                      <Fade delay={500} when={projectS == 55}>
                        <div className="but-p">
                          <a
                            href={!magic ? "https://github.com/ValentinAhrend/bwin" : "#"}
                            className="btz"
                            style={{
                              backgroundColor: "rgb(227, 227, 227)",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 Visit Project on Github
                            </div>
                          </a>
                          <a
                            href={!magic ? "https://github.com/ValentinAhrend/bwin/raw/refs/heads/main/bwinf41valentin_ahrend.zip" : "#"}
                            className="btz"
                            style={{
                              backgroundColor: "rgb(227, 227, 227)",
                              color: "black",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                             ⬇️ Download macOS-Program
                            </div>
                          </a>
                          <a
                            onClick={() => {
                              setProjectS(255);
                              new Promise((x) => setTimeout(x, 500)).then(
                                (y) => {
                                  setProjectS(-1);
                                }
                              );
                            }}
                            className="btz btz-y "
                            style={{
                              border: "1px solid rgb(227, 227, 227)",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              Close Details
                            </div>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <div className="project-text">
                      <div className="project-text0">
                      <b>BWINF!</b> is the national computer science competition of Germany. I took part in this competition. You can see the code, the docs and the program as a zip below.
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "50px" }}></div>
                <div
                  className="project-file"
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    if (projectS == 1 || projectS == 201) {
                      setProjectS(-1);
                    } else {
                      setProjectS(1);
                    }
                  }}
                  style={{
                    height:
                      projectS == 1 || projectS == 201 ? "900px" : "400px",
                  }}
                >
                  <div className="project-img">
                    <div className="app-log">
                      <img
                        className="app-logo0"
                        src={projectAdLogo}
                        style={{
                          width: window.innerWidth < 1000 ? "300px" : "320px",
                        }}
                        alt={"KreativTest"}
                      ></img>
                    </div>
                  </div>
                  <div className="project-back-blur"></div>
                  {projectS == 1 || projectS == 201 ? (
                    <div className={"project-long"}>
                      <div className="project-text0">
                        With <b>ProjectAd</b> you can earn money by solving
                        quotations. For each quote you fulfill you receive
                        QuotePoints. These QuotePoints can be converted into
                        real money, giftcards and more. Make money with your
                        intellect!
                      </div>
                      <Fade when={projectS == 1}>
                        <div
                          className="project-text0"
                          style={{ maxHeight: "500px", height: "auto" }}
                        >
                          ProjectAd is currently an iOS, <del>Android</del>{" "}
                          Application built with Flutter, Firebase and Smaller
                          Server Applications.
                        </div>
                      </Fade>
                      <Fade delay={500} when={projectS == 1}>
                        <div className="but-p">
                          <a
                            href={
                              !magic
                                ? "https://apps.apple.com/de/app/projectad/id1539175175"
                                : "#"
                            }
                            className="btz"
                            style={{
                              backgroundColor: "rgba(22,22,22,0.4)",
                              color: "white",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 See on App Store
                            </div>
                          </a>
                          <a
                            href={
                              !magic
                                ? "https://apps.apple.com/de/app/projectad/id1539175175"
                                : "#"
                            }
                            className="btz"
                            style={{
                              marginTop: "15px",
                              backgroundColor: "rgba(22,22,22,0.4)",
                              color: "white",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 Visit projectad.de
                            </div>
                          </a>
                          <a
                            href={
                              !magic
                                ? "https://m.apkpure.com/de/projectad/com.app.projectad"
                                : "#"
                            }
                            className="btz"
                            style={{
                              marginTop: "15px",
                              backgroundColor: "rgba(22,22,22,0.4)",
                              color: "white",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 Android Release APK
                            </div>
                          </a>
                          <a
                            href={!magic ? "mailto:info@projectad.de" : "#"}
                            className="btz"
                            style={{
                              marginTop: "15px",
                              backgroundColor: "rgba(22,22,22,0.4)",
                              color: "white",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              📧 Contact Email
                            </div>
                          </a>
                          <a
                            onClick={() => {
                              setProjectS(201);
                              new Promise((x) => setTimeout(x, 500)).then(
                                (y) => {
                                  setProjectS(-1);
                                }
                              );
                            }}
                            className="btz btz-e"
                            style={{
                              border: "1px solid rgb(22,22,22)",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              Close Details
                            </div>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <div className="project-text">
                      <div className="project-text0">
                        With <b>ProjectAd</b> you can earn money by solving
                        quotations. For each quote you fulfill you receive
                        QuotePoints. These QuotePoints can be converted into
                        real money, giftcards and more. Make money with your
                        intelect!
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "50px" }}></div>
                <div
                  className="project-file"
                  onClick={() => {
                    if (magic) {
                      return;
                    }
                    if (projectS == 2 || projectS == 202) {
                      setProjectS(-1);
                    } else {
                      setProjectS(2);
                    }
                  }}
                  style={{
                    height:
                      projectS == 2 || projectS == 202 ? "900px" : "400px",
                  }}
                >
                  <div className="project-img">
                    <div className="app-log">
                      <img
                        className="app-logo0"
                        src={EL}
                        style={{
                          width: window.innerWidth < 1000 ? "300px" : "320px",
                        }}
                        alt={"Exsent"}
                      ></img>
                    </div>
                  </div>
                  <div className="project-back-blur"></div>
                  {projectS == 2 || projectS == 202 ? (
                    <div className={"project-long"}>
                      <div className="project-text0">
                        <b>Exsent</b> is an Android app that works like a store
                        for short code functions. Inside the app you can
                        download in-app executable 'functions'.
                      </div>
                      <Fade when={projectS == 2}>
                        <div
                          className="project-text0"
                          style={{ maxHeight: "500px", height: "auto" }}
                        >
                          {
                            "Exsent is purely written in Java (also the design) and decodes incoming .dex files (an android app contains of mutliple .dex files with all code inside) into executable Java Runtime Classes."
                          }
                        </div>
                      </Fade>
                      <Fade delay={500} when={projectS == 2}>
                        <div className="but-p">
                          <a
                            href={
                              !magic
                                ? "https://valentinahrend123.medium.com/exsent-project-create-your-own-functions-cf718e65aae9"
                                : "#"
                            }
                            className="btz"
                            style={{
                              backgroundColor: "rgb(186,224,223)",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🗞 Medium Post
                            </div>
                          </a>
                          <a
                            href={
                              !magic
                                ? "https://play.google.com/store/apps/details?id=com.exsent.app&hl=de&gl=US"
                                : "#"
                            }
                            className="btz"
                            style={{
                              marginTop: "15px",
                              backgroundColor: "rgb(179,190,237)",
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              🔗 Play Store
                            </div>
                          </a>
                          <a
                            onClick={() => {
                              setProjectS(202);
                              new Promise((x) => setTimeout(x, 500)).then(
                                (y) => {
                                  setProjectS(-1);
                                }
                              );
                            }}
                            className="btz btz-g "
                            style={{
                              border: "1px solid rgb(186,224,223)",
                              textDecoration: "none",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                              }}
                            >
                              Close Details
                            </div>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <div className="project-text">
                      <div className="project-text0">
                        <b>Exsent</b> is an Android app that works like a store
                        for short code functions. Inside the app you can
                        download in-app executable 'functions'.
                      </div>
                    </div>
                  )}
                </div>
                <div className="project-hold"></div>
              </div>
            </div>
          </div>
        </Fade>
        <div
          className="row-seil"
          style={{ height: "580px", position: "relative" }}
        >
          <div className="row-seil-tag-x" style={{ top: "190px" }}>
            Music
          </div>
          <div className="row-seil-container-x"></div>
          <div className="row-seil-button-2">
            <div className="row-seil-pickle"></div>
            <Fade duration={500}>
              <div
                className={
                  "row-seil-obj " +
                  (window.innerWidth < 1000 ? "row-seil-obj-x" : "")
                }
                style={{ height: "400px", maxWidth: "900px" }}
                onClick={() => {
                  console.log("/");
                  updateRan(10);
                }}
              >
                <Fade
                  duration={500}
                  when={(currentRandom2 < 3 && projectS == -1) || magic}
                >
                  {
                    <iframe
                      onClick={() => {
                        updateRan(10);
                      }}
                      src={
                        currentRandom == 0
                          ? "https://open.spotify.com/embed/playlist/03IQUAVVqXJT1uJLa81qfw?utm_source=generator&theme=0"
                          : currentRandom2 == 1
                          ? "https://open.spotify.com/embed/playlist/02WiS7rEVJ1WVF6EnMkn9L?utm_source=generator"
                          : "https://open.spotify.com/embed/playlist/3ZgavXmfUAexXx95Eds9YL?utm_source=generator"
                      }
                      style={{
                        width: "100%",
                        height: "380px",
                        borderRadius: "25px",
                      }}
                      frameBorder="0"
                      allowFullScreen=""
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    ></iframe>
                  }
                </Fade>
              </div>
            </Fade>
          </div>
        </div>
        <div className="bottom-header" id="contact">
          <div className="bottom-f" style={{ fontSize: "16px" }}>
            <div
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => {
                //open github
                if (magic) {
                  return;
                }
                window.open("https://github.com/ValentinAhrend");
              }}
            >
              Github
            </div>
            •
            {/*<div style={{"marginRight": "10px", "marginLeft": "10px", "cursor": "pointer", "fontSize": "16px"}} onClick={()=>{
                    //open medium
                    if(magic){return;}
                    window.open("https://fiverr.com/valentinahrend");
                }}>Fiverr</div>
                • */}
            <div
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => {
                //open medium
                if (magic) {
                  return;
                }
                window.open(
                  "https://www.linkedin.com/in/valentin-ahrend-583983311/"
                );
              }}
            >
              LinkedIn
            </div>
            {/*<div style={{"marginRight": "10px", "marginLeft": "10px", "cursor": "pointer", "fontSize": "16px"}} onClick={()=>{
                    //open leetcode
                    if(magic){return;}
                    window.open("https://leetcode.com/ValentinAhrend/");
                }}>LeetCode</div>
                • */}
            <div
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => {
                //open instagram
                if (magic) {
                  return;
                }
                window.open("https://instagram.com/vali.b05");
              }}
            >
              Instagram
            </div>
            •
            <div
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => {
                //open StackOverFlow
                if (magic) {
                  return;
                }
                window.open(
                  "https://stackoverflow.com/users/12961658/valispaceprogramming"
                );
              }}
            >
              StackOverFlow
            </div>
            {/*• */}
            {/*<div style={{"marginRight": "10px", "marginLeft": "10px", "cursor": "pointer", "fontSize": "16px"}} onClick={()=>{
                    //open StackOverFlow
                    if(magic){return;}
                    window.open("https://bere.al/valentin_fr");
                }}>BeReal</div>*/}
            •
            <div
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => {
                //open StackOverFlow
                if (magic) {
                  return;
                }
                window.open("mailto:valentinahrend@gmail.com");
              }}
            >
              Email
            </div>
          </div>
          <div
            className={magic ? "magic-wand-active" : "magic-wand"}
            style={{ cursor: "pointer", fontSize: "16px" }}
            onClick={() => {
              setProjectS(-1);
              setMagic(!magic);
            }}
          >
            <div
              style={{
                display: "inline",
                marginTop: "auto",
                marginBottom: "auto",
                verticalAlign: "middle",
                textAlign: "center",
                width: "100%",
                color: "white",
              }}
            >
              Magic Wand
            </div>
          </div>
        </div>
        <div className="bottom-header-mobile">
          <div
            className="mobile-tcx"
            onClick={() => {
              if (magic) {
                return;
              }
              window.open("https://github.com/ValentinAhrend");
            }}
          >
            <div className="mobile-otc">Github</div>
          </div>
          <div
            className="mobile-tcx"
            onClick={() => {
              if (magic) {
                return;
              }
              window.open("https://fiverr.com/valentinahrend");
            }}
          >
            <div className="mobile-otc">Fiverr</div>
          </div>
          <div
            className="mobile-tcx"
            onClick={() => {
              if (magic) {
                return;
              }
              window.open("https://leetcode.com/ValentinAhrend/");
            }}
          >
            <div className="mobile-otc">LeetCode</div>
          </div>
          <div
            className="mobile-tcx"
            onClick={() => {
              if (magic) {
                return;
              }
              window.open("https://instagram.com/vali.b05");
            }}
          >
            <div className="mobile-otc">Instagram</div>
          </div>
          <div
            className="mobile-tcx"
            onClick={() => {
              if (magic) {
                return;
              }
              window.open(
                "https://stackoverflow.com/users/12961658/valispaceprogramming"
              );
            }}
          >
            <div className="mobile-otc">StackOverFlow</div>
          </div>
          <div
            className="mobile-tcx"
            style={{ marginBottom: "20px" }}
            onClick={() => {
              if (magic) {
                return;
              }
              window.open("https://bere.al/valentin_fr");
            }}
          >
            <div className="mobile-otc">BeReal</div>
          </div>
          <div
            className="mobile-tcx"
            style={{ marginBottom: "20px" }}
            onClick={() => {
              if (magic) {
                return;
              }
              window.open("mailto:valentinahrend@gmail.com");
            }}
          >
            <div className="mobile-otc">Email</div>
          </div>
          <div
            className={magic ? "mobile-tci-active" : "mobile-tci"}
            onClick={() => {
              setProjectS(-1);
              setMagic(!magic);
            }}
          >
            <div className="mobile-otc">Magic Wand</div>
          </div>
        </div>
        <div className="footer">
          © 2024 Valentin Ahrend, built with pure React
        </div>
      </div>
    </div>
  );
}
/**/

/*
Android,
Dart,
Figma
Flutter,
HTML/CSS,
iOS,
Java,
JavaScript,
NLP
Python
React,
Selenium,
SpringBoot,
Swift,
*/
export default Main;
